import { createContext } from 'react'

import { AuthenticatedUser } from '@unreserved-frontend-v2/api/generated/graphql/types'
import { NoOp } from '@unreserved-frontend-v2/utils/noop'

import { User } from '../../types/user'

export enum PreviewViewsType {
  LIST = 'list',
  GRID = 'grid',
  TABLE = 'table',
}

export interface UserContextType {
  /**
   * Is there a token in state.
   *
   * Use this in SSR when an extra call to GetUser is undesirable
   * NOTE: This doesn't guarantee the user is logged in as a token can be expired or invalid
   * 401 network status will occur if it's a bad token
   */
  hasToken: boolean
  /** User state in UserContext */
  user: User
  /** True if the user info is currently being loaded. False otherwise. */
  isLoading: boolean
  /** If there's an error on getting the user */
  isError?: boolean
  /** Whether or not the user is considered to be logged in */
  isLoggedIn: boolean
  /** Method to update user & token state in UserContext & cookies */
  updateUser(user: AuthenticatedUser): void
  /** Method to completely remove the user. */
  clearUser(): void
  /** Boolean to track if a user has accepted CREA's term of use at any point on their browser */
  CREATermsAccepted: boolean
  updateCREATermsAccepted(accepted: boolean): void
  /** A randomized UUID Used soley for CREA tracking purposes */
  uuid?: string
  /** Boolean to track if a completed call was made for user data */
  userReady: boolean
  /** Boolean to track whether or not the user is viewing condensed summaries */
  condensedSummary: boolean
  /** Method to toggle whether or not the user is viewing condensed summaries */
  toggleCondensedSummary: (isCondensed: boolean) => void
  /** Currently saved `PreviewViewsType` that the user selected */
  previewView?: PreviewViewsType
  /** Method to toggle whether or not the user is viewing condensed summaries */
  setPreviewViewType: (view?: PreviewViewsType) => void
  /** method to only update the token cookie */
  updateUserCookieToken: (token: string) => void
  /** boolean to determine whether a user has access to admin */
  hasAdminAccess: boolean
}

// NOTE:  The default value will be used only if useContext doesn't find a UserContext provider
//        up the component tree: https://reactjs.org/docs/context.html#reactcreatecontext
export const UserContext = createContext<UserContextType>({
  hasToken: false,
  user: undefined,
  isLoading: false,
  isError: false,
  uuid: undefined,
  updateUser: NoOp,
  clearUser: NoOp,
  isLoggedIn: false,
  CREATermsAccepted: true,
  updateCREATermsAccepted: NoOp,
  userReady: false,
  condensedSummary: false,
  toggleCondensedSummary: NoOp,
  setPreviewViewType: NoOp,
  updateUserCookieToken: NoOp,
  hasAdminAccess: false,
})
